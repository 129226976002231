.inputStyle,.inputStyle:focus, .inputStyle:active {
  appearance: auto;
    border: 2px solid #0072B2;
    border-radius: 10px;
    background-Color: #F4FAFF; 
    padding: 5px;
    width: 100%;
    font-family: "Poppins-Regular";
    outline: none;
    box-shadow:none ;
  }

  
  .errorBorder{
    border: 3px solid #FE8485 !important;
    background-color: #FFF1F1;
  }

  .PhoneInputInput,.PhoneInputInput:focus-visible,.PhoneInputInput:active,.PhoneInputInput:hover{
    background: inherit !important;
    border: none !important;
    outline: none;
  }


  .activeTab {
    color: #0072B2;
    font-weight:bold;
  }

  .nav-tabs .nav-link:focus,  .nav-tabs .nav-link:hover{
    border: none;
    transform: translate(0);
  }

  h5{
    font-family:"Poppins-Semibold";
  }

  label{
    font-family: "Poppins-Medium"
  }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Optional: Remove extra spacing */
}


.otpInput{
  border: 2px solid #0072B2;
  border-radius: 10px;
  background-Color: #F4FAFF; 
  padding: 5px;
  font-family: "Poppins-Regular";
  outline: none;
  text-align: center;
  max-width:60px;
  height:60px
}

.custom-select .css-3w2yfm-ValueContainer {
  display: flex !important;
  flex-wrap: nowrap !important;
  white-space: nowrap !important; 
}


.home #loader-container {
  position: fixed;
  top: 0;
  left: 350px;
  bottom: 0;
  right: 0;
  height: 100%;
  background: rgba(10, 10, 10, 0.363); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the loader is displayed above other content */
}
.sidebarnav.close ~ .home #loader-container {
  position: fixed;
  top: 0;
  left: 100px;
  bottom: 0;
  right: 0;
  height: 100%;
  background: rgba(10, 10, 10, 0.363); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the loader is displayed above other content */
}

#loader-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  background: rgba(10, 10, 10, 0.363); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the loader is displayed above other content */
}

#loader {
  background: white;
  width: 110px;
  height: 110px;
  text-align: center;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.input-container {
  position: relative;
}

.icon .fa-pencil{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #777;
  cursor: pointer;
}


.activecircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #0072B2; /* Change this color as desired */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.inactivecircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color:#777; /* Change this color as desired */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}


.dataTable div[data-column-id="64"] {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: #FFFFFF; 
  /* Additional styles as needed */
}

.dataTable .cYMhfa{
  padding-left: 0 !important;
  padding-right: 0 !important;
}


.dataTable div[data-column-id="1"] {
  border-left:none 
  
  /* Additional styles as needed */
}

.dataTable .rdt_TableCol{
  font-family: "Poppins-SemiBold";
  font-size: 16px;
  border-left:solid 1px #0072B2;
  justify-content: center !important;
}

.dataTable .rdt_TableCell{
  border-left:solid 1px #0072B2;
  border-top: solid 1px #0072B2;
  justify-content: center !important;
}

.dataTable{
  border: solid 1px #0072B2;
  border-bottom-left-radius: 10%;
}

.dataTable div.rdt_TableRow:nth-child(odd) {
  background-color: rgba(0, 114, 178, 0.1); 
}



.tooltip-text {
  visibility: hidden;
  width: max-content;
  background-color: rgba(10, 10, 10, 0.6) ;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 0;
  top: 30px; /* Adjust the position */
  left: 50%; /* Adjust the position */
  transform: translateX(-50%);
}


.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  background-color: rgba(10, 10, 10, 0.6) !important;
  color: #fff !important; 
  text-align: center !important;
  border-radius: 4px !important;
  padding: 5px !important;
}

.fa-info-circle:hover , .icon:hover + .tooltip-text{
  visibility: visible;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: rgba(0, 114, 178, 0.2) !important;
  border: none !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
  box-shadow: none !important;
}


.drit:focus, .drit:hover {
color: #0072B2 !important;
background-color: #DCF2FF !important;
}



.bulkcontainer{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  border-color: #0072B2;
  background-color:rgba(214, 232, 243, 1);
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
  margin: 0 auto;
  font-family:"Poppins-Medium";
}

.attachedFile{
  background-color: #ffffff;
  color: #000000;
  font-family: "Poppins-Medium";
  font-size: 18px;
  margin:20px;
  padding:20px;
  border: 2px solid #0072B2;
}

.titlename{
  display: flex;
  align-items: start;
  font-size: 18px;
  color:#05004E;
  font-weight: 600;
  font-family: 'Poppins-Regular';
}

.home{
  padding: 40px;
}

.managementTable{
  border: 1px solid #ECEEF6;
  border-radius: 6px !important;
}

.managementTable .rdt_TableCol{
  color:#828690;
  font-size: 14.5px;
  font-weight: 600;
  background-color: #E4F3FF;
  border:none;
 font-family: 'Poppins-Regular';
 justify-content: center !important;
}

.managementTable .rdt_TableCell{
  color:#1E1E1E;
  font-size: 14.5px;
  font-weight: 500;
  justify-content: center !important;
}

.managementTable .rdt_TableRow{
  margin: 5px;
  width: auto !important;
  font-family: 'Poppins-Regular';
}

.uploadDocTable{
  border: 1px solid #ECEEF6;
  border-radius: 6px !important;
}

.uploadDocTable .rdt_TableCol{
  color:#828690;
  font-size: 14.5px;
  font-weight: 600;
  background-color: #E4F3FF;
  border:none;
 font-family: 'Poppins-Regular';
 justify-content: center !important;
}

.uploadDocTable .rdt_TableCell{
  color:#1E1E1E;
  font-size: 14.5px;
  font-weight: 500;
}
.uploadDocTable .rdt_TableCell[data-column-id='1']{
  justify-content: center !important;
}
.uploadDocTable .rdt_TableCell[data-column-id='2']{
  justify-content: center !important;
}
.uploadDocTable .rdt_TableCell[data-column-id='3']{
  justify-content: center !important;
}

.uploadDocTable .rdt_TableRow{
  margin: 5px;
  width: auto !important;
  font-family: 'Poppins-Regular';
}


.userManagementTable{
  border: 1px solid #ECEEF6;
  border-radius: 6px !important;
}

.userManagementTable .rdt_TableCol{
  color:#828690;
  font-size: 14.5px;
  font-weight: 600;
  background-color: #E4F3FF;
  border:none;
 font-family: 'Poppins-Regular';
 justify-content: center !important;
}

.userManagementTable .rdt_TableCell{
  color:#1E1E1E;
  font-size: 14.5px;
  font-weight: 500;
}

.userManagementTable .rdt_TableCell[data-column-id='1']{
  justify-content: center !important;
}
.userManagementTable .rdt_TableCell[data-column-id='4']{
  justify-content: center !important;
}
.userManagementTable .rdt_TableCell[data-column-id='3']{
  justify-content: center !important;
}
.userManagementTable .rdt_TableCell[data-column-id='role_name']{
  justify-content: center !important;
}


.userManagementTable .rdt_TableRow{
  margin: 5px;
  width: auto !important;
  font-family: 'Poppins-Regular';
}

.rdt_TableHeader{
 padding: 0% !important;
 min-height: auto !important;
 margin-top: 20px;
 margin-bottom: 5px;
}



.rdt_Pagination{
  border:none !important;
}

.rdt_Table .sc-bmzYkS{
 font-family: 'Poppins-Regular' !important;
}


.managementTableTitle{
  text-align: left;
  padding: 0%;
  color:#05004E;
  font-size: 15px;
  font-weight: 600;
  line-height: 32px;
  font-family: 'Poppins-Regular';
}

.managementbutton{
  background-color: #0072B2;
  border:none;
  color:#FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.14);
  font-family: 'Poppins-Medium';
}

.managementbutton:hover{
  background-color: #0072B2;
  border:none;
  color:#FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.14);
  font-family: 'Poppins-Medium';
}

.paypalbuttons{
  display:flex;
  justify-content: center;
}

.paypal-button.paypal-button-color-gold{
  background-color:#0072B2 !important;
}

.paypal-buttons.paypal-buttons-context-iframe.paypal-buttons-label-subscribe.paypal-buttons-layout-vertical{
  min-width: 600px !important;
}

div.paypal-button.paypal-button-shape-rect {
  border-radius: 26px !important;
}

.managementmodal .modal-title{
  color: #1E1E1E;
  font-weight: 600;
  font-size: 16.5px;
}

.managementmodal .btn-close{
  color: #1E1E1E;
  font-weight: 600;
  font-size: 12px;
}
.managementmodal .cancel{
  border: 1px solid #0072B2;
  background-color: #E3F3FF;
  color:#0072B2;
  font-weight: 600;
  font-size: 13px;
}
.managementmodal .submit{
  border: 1px solid #0072B2;
  background-color: #0072B2;
  color:#FFF;
  font-weight: 600;
  font-size: 13px;
}

.managementmodal .modal-header, .managementmodal .modal-footer{
  border-color: #FFF;
}

.managementmodal .modalbodylabel{
  color:#425166;
  font-size: 14.5px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

.managementmodal .modalbodyinput{
  border: 1px solid #E7EAE9;
  border-radius: 7px;
  color:#131123;
  font-weight: 600;
  font-size: 14.5px;
}

.subscriptionCard{
  display: flex;
  justify-content:center;
  margin: 20px;
}

.subscriptionCard .card1{
  border:none;
}

.subscriptionCard .card2{
  width:206px;
  border: 1.11px solid #0072B2;

  border-top-right-radius: 0px;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 0px;
}

.subscriptionCard .card3{
  width:222px;
  border-top-right-radius: 13px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 13px;
  background-color: #0072B2;
  border: 1.11px solid #0072B2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.52);
}

.subscriptionCard .cardHeader{
min-height: 150px;
}

.subscriptionCard .cardtext1, .subscriptionCard .cardtext2, .subscriptionCard .cardtext3{
  min-height: 50px;
  font-size: 20px;
  font-weight: 500;
}

.subscriptionCard .card3 .cardtext1, .subscriptionCard .card3 .cardtext2, .subscriptionCard .card3 .cardtext3{
  min-height: 50px;
  font-size: 20px;
  font-weight: 500;
  color:#FFF;
}

.subscriptionCard .card2 .cardtext1, .subscriptionCard .card2 .cardtext2, .subscriptionCard .card2 .cardtext3{
  min-height: 50px;
  font-size: 20px;
  font-weight: 500;
  color:#0072B2;
}

.subscriptionCard .card {
  align-items: start;
  padding:35px;
}

.orgselect.css-b62m3t-container {
  /* Override styles here */
  padding: 0 !important;
  margin: 0 !important;
}

.orgselect .searchWrapper .searchBox {
  width: 100% !important;
}

.permissionBlocks 
{
  color: #0072B2;
  border:none;
  background-color: rgba(0, 114, 178, 0.31);
  font-size: 14.5px;
  font-weight: 400;
  cursor:auto;
}

.permissionBlocks:hover 
{
  color: #0072B2;
  border:none;
  background-color: rgba(0, 114, 178, 0.31);
  font-size: 14.5px;
  font-weight: 400;
  cursor:auto;
}

.disabledButton {
  background-color: #a7a3a3 !important; /* Gray */
  color: #fff !important;
  font-family: "Poppins-Medium" !important;
  font-size: 16px !important;
  position: relative;
}

.distooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background-color: rgba(10, 10, 10, 0.6);
  color: #fff;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100000 !important;
}

.disabledButton:hover .distooltip {
  opacity: 1 !important;
}

.disable.drit:focus, .disable.drit:hover {
  color: #a7a3a3  !important;
  background-color: #DCF2FF !important;
  }

.orgEmpName{
    color: #1E1E1E;
    font-family: 'Poppins-SemiBold';
    font-size: 16.5px;
  }

.orgRoleName{
    color: #1e1e1e;
    font-family: 'Poppins-light';
    font-size: 14.5px;
  }

.reportingLable{
      color: #1e1e1e;
      font-family: 'Poppins-Regular' !important;
      font-size: 14.5px;
      margin: 5px;
  }

.orgNameCard{
    color: #1E1E1E;
    font-family: 'Poppins-Regular' !important;
    font-size: 14.5px;
  }

  .policyModal .modal-title{
    color: #1E1E1E;
    font-weight: 600;
    font-size: 16.5px;
  }
  
  .policyModal .btn-close{
    color: #1E1E1E;
    font-weight: 600;
    font-size: 12px;
  }
  .policyModal .cancel{
    border: 1px solid #0072B2;
    background-color: #E3F3FF;
    color:#0072B2;
    font-weight: 600;
    font-size: 13px;
  }
  .policyModal .submit{
    border: 1px solid #0072B2;
    background-color: #0072B2;
    color:#FFF;
    font-weight: 600;
    font-size: 13px;
  }
  
  .policyModal .modal-header, .policyModal .modal-footer{
    border-color: #FFF;
  }
  
  .policyModal .modalbodylabel{
    color:#425166;
    font-size: 14.5px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .policyModal .modal-body{
    font-size: 16px;
    font-family: 'Poppins-Medium';
    padding:40px 40px;
  }

  .policyModal .modal-footer{
    font-size: 16px;
    font-family: 'Poppins-Medium';
    font-weight: 600;
  }

  .subscribedCard::before {
    content: "";
    background-image: url('../Images/crown.svg'); /* Replace 'path/to/crown.png' with the actual path to your crown image */
    background-size: contain; /* You can adjust this based on your image dimensions */
    background-repeat: no-repeat;
    width: 70px; /* Adjust the width of the crown image as needed */
    height: 70px; /* Adjust the height of the crown image as needed */
    position: absolute;
    top: -35px; /* Adjust the distance from the top of the card */
    left: -35px; /* Adjust the distance from the left of the card */
    z-index: 1; /* Ensure the crown is on top of other elements in the card */
  }

  .faqcategory {
    background-color: #CFCFCF;
    font-family: 'Poppins-Medium';
    color: #25282B;
    font-size: 16px;
  }

  .faqcategory.activecat ,  .faqcategory.activecat:hover {
    background-color: #0072B2;
    font-family: 'Poppins-Medium';
    color: #FFF;
    font-size: 16px;
  }

  .faqcategory:hover {
    background-color: #0072B2;
    font-family: 'Poppins-Medium';
    color: #FFF;
    font-size: 16px;
  }

  .FaqAcQue{
    background-color: #FFF !important;
    border-radius: 14px !important;
    color:#170F49;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins-Regular';
  }

  .FaqAc{
    border: 1px solid #000000;
    border-radius: 14px !important;
    margin-bottom: 20px;
  }

  .FaqAcAns{
    color:#6F6C90;
    font-size: 18px;
    font-family: 'Poppins-Regular';
  }


  .stripebutton,.stripebutton:hover{
    background:linear-gradient(to right, #6772E5 0%, #8089F0 100%);
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 18px;
  }



  .subscriptionTable .sc-imWYAI {
    padding: 0;
  }

  .subscriptionTable .rdt_TableRow{
    border: none !important;
  }

  .subscriptionTable .rdt_TableHeadRow{
    border: none !important;
  }

  .subscriptionTable [data-column-id="2"] {
    border-left: 1px solid #0072B2 !important;
  }

  .subscriptionTable .rdt_TableCell[data-column-id="1"] {
    background-color: #0072B2 !important;
  }
  .subscriptionTable .rdt_TableCell[data-column-id="3"] {
    background-color: #0072B2 !important;
  }

  .subscriptionTable .rdt_TableCell[id="cell-1-1"] {
    border-top-left-radius: 12px !important;
  }

  .subscriptionTable .rdt_TableCell[id="cell-1-4"] {
    border-bottom-left-radius: 12px !important;
  }

  .subscriptionTable .rdt_TableCell[id="cell-3-4"] {
    border-bottom-right-radius: 12px !important;
  }
  .subscriptionTable #cell-3-4 {
    border-bottom-right-radius: 10px; /* Adjust the radius as needed */
  }

  .subscriptionTable .rdt_TableCol_Sortable[data-column-id="2"],.subscriptionTable .rdt_TableCol[data-column-id="2"]  {
    border-top-left-radius: 12px !important;
  }

  .subscriptionTable .rdt_TableCol [data-column-id="1"],.subscriptionTable .rdt_TableCol_Sortable[data-column-id="1"]  {
    background-color: transparent !important;
  }

  .subscriptionTable .rdt_TableRow:last-child [data-column-id="2"] {
    border-bottom: 1px solid #0072B2 !important;
  }

  .subscriptionTable .rdt_TableCol [data-column-id="2"] {
    border-top: 1px solid #0072B2 !important;
  }

  .subscriptionTable .rdt_TableCol_Sortable[data-column-id="2"] {
    border-left: none !important; /* Remove border from header */
  }
  
  .subscriptionTable .rdt_TableHead .rdt_TableCol[data-column-id="3"] {
    position: relative;
  }
  
  .subscriptionTable .rdt_TableHead .rdt_TableCol[data-column-id="3"]::before {
    content: "";
    background-image: url('../Images/crown.svg'); /* Replace with the actual path to your crown image */
    background-size: contain;
    background-repeat: no-repeat;
    width: 70px; /* Adjust the width of the crown image */
    height: 70px; /* Adjust the height of the crown image */
    position: absolute;
    top: -35px; /* Adjust the distance from the top of the column header */
    right: -35px; /* Adjust the distance from the right of the column header */
    z-index: 1; /* Ensure the crown is on top of other elements in the column header */
    overflow: visible; /* Allow overflow to be visible */
  }

  .subscriptionTable {
    overflow: visible !important;
  }  

.subscriptionTable .rdt_TableCell{
  font-size: 18px;
  font-family: 'Poppins-Medium';
}

.subscriptionTable .rdt_TableBody .rdt_TableRow {
  height: 80px; /* Adjust the height as needed */
}



.notifyBar{
  font-family: 'Poppins-Medium' !important;
  font-size: 16px !important;
  color:#fff !important;
  z-index: 999 !important;
  background-color: rgba(10, 10, 10, 0.706) !important; /* Semi-transparent white background *//* Semi-transparent background */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1)!important; 
}

.dayAlert {
  text-align: left !important;
  align-items: center !important;
  border: 1px solid #B8DAFF !important;
  background-color:#CCE5FF !important;
  color: #004085 !important;
  font-size: 16px !important;
  font-family: 'Poppins-Medium' !important;
}

.pininfofill{
  color: #004085 !important;
  font-size: 25px !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 5px;
}


.orgselect .css-1u9des2-indicatorSeparator,.orgselect .css-1xc3v61-indicatorContainer{
  display: none !important;
}



.PhoneInputCountrySelect__options {
  max-height: 300px !important; 
  height:300px !important;/* Set the height of the options box */
  overflow-y: auto !important;  /* Add a scrollbar if the content exceeds the height */
}